<template>
  <div>
    <div v-if="userInfo.isDelete == 3" class="deleteClass">
      <img src="../../../../assets/images/newUserInfo/placeholder.png" alt="">
    </div>
    <div v-else class="personalprofile">
      <div class="introBox" v-if="handleIntro.length !== 0 && handleRichIntro === ''">
        <DisplayComponent v-for="(item, index) in handleIntro" :key="index" :item="item"></DisplayComponent>
      </div>
      <div class="introBox richIntroBox" v-if="handleRichIntro !== ''" v-html="handleRichIntro"></div>
      <div class="likeBox" v-if="userInfo.likeCount !== '0'">
        <div class="likeStatistics">
          <div class="likeIcon">
            <i class="iconfont_Me icon_fabulous iconStlye" :style="{ color: '#200E32' }"></i>
          </div>
          <div class="likeCount">
            {{ userInfo.likeCount }}
          </div>
        </div>
        <div class="likePeopleBox">
          <div class="likeItemsLine" v-for="(fatherItem, fatherIndex) in userInfo.likeProviders" :key="fatherIndex"
            :style="{
              justifyContent: fatherItem.length && fatherItem.length < 6 ? 'flex-start' : 'space-between'
            }">
            <div class="likeItem" v-for="(childItem, childIndex) in fatherItem" :key="childIndex" :style="{
              marginRight: fatherItem.length && fatherItem.length < 6 ? '21px' : '0'
            }" @click.stop="handlerToUser(childItem.providerId)">
              <AvatarBox :src="childItem.userAvatar" :country="childItem.country.toString()" :nationalFlagSize="16">
              </AvatarBox>
            </div>
          </div>
        </div>
      </div>
      <div class="commentBox" v-if="userInfo.commentCount !== '0'">
        <div class="commentStatistics">
          <div class="commentIcon">
            <i class="iconfont_Me icon_comment iconStlye" :style="{
              color: '#200E32'
            }"></i>
          </div>
          <div class="commentCount">
            {{ userInfo.commentCount }}
          </div>
        </div>
        <Comments :id="userInfo.providerId" :count="userInfo.commentCount" :showCount="false" ref="comments" type="user"
          :noBottomBorder="true" @replayChild="handlerEmitReplayChild" />
      </div>
      <PlaceholderMap v-if="showPlaceholder"></PlaceholderMap>
      <div class="operatingFlexBox">
        <div class="commonButtonStyle commentButton">
          <div class="loginPerson">
            <div class="notLoggedIn" v-if="loginUserImage === ''">
              <i class="iconStyle iconfont_Me icon_user-fill"></i>
            </div>
            <van-image width="100%" height="100%" fit="cover" v-else :src="loginUserImage" />
          </div>
          <div class="iconBox" :style="{ width: commentInput ? '200px' : '50px' }">
            <div class="inputerAndSendBox" v-if="commentInput">
              <input class="commentInput" v-model="replayContent" ref="commentInput" type="text"
                @blur.stop="commentInputBlur">
              <div class="sendComment" @click.stop="startSubmitComment">
                <i class="sendStyle iconfont_Me icon_send"></i>
              </div>
            </div>
            <i v-else class="iconStyle iconfont_Me icon_edit1" @click.stop="handlerEmitReplay"></i>
          </div>
        </div>
        <div class="commonButtonStyle likeButton" @click.stop="startLike">
          <i class="iconStyle iconfont_Me" :class="userInfo.likeStatus ? 'icon_fabulous-fill' : 'icon_fabulous'"
            :style="{ color: userInfo.likeStatus ? '#fe0657' : '#200E32' }"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DisplayComponent from "../common/displayComponent";
import { formartImageUrl } from "@/utils/help";
import Comments from "@/components/business/h5/comments";
import AvatarBox from "../common/avatarBox";
import PlaceholderMap from "../common/placeholderMap";
import {
  replayViews,
} from "@/api/webApi";
export default {
  components: {
    DisplayComponent,
    Comments,
    AvatarBox,
    PlaceholderMap
  },
  props: {
    // 用户信息
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
    // 判断是否登录
    hasLogin() {
      let hasLogin = false;
      let loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        hasLogin = true
      }
      return hasLogin;
    },
    // 登录用户的头像信息
    loginUserImage() {
      let loginUserImage = "";
      if (this.hasLogin) {
        loginUserImage = this.$store.state.User.userinfo.avatar
      }
      return loginUserImage;
    },
    // 处理过的个人简介信息
    handleIntro() {
      let handleIntro = [];
      if (this.userInfo.intro && this.userInfo.intro !== "") {
        try {
          handleIntro = JSON.parse(this.userInfo.intro.replace(/\n/g, ""));
        } catch (error) { console.log(error, this.userInfo.intro) }
      }
      return handleIntro;
    },
    // 处理过的富文本内容 
    handleRichIntro() {
      let handleRichIntro = ""
      if (this.userInfo.richIntro && this.userInfo.richIntro !== "") {
        try {
          handleRichIntro = this.userInfo.richIntro.replace(RegExp("width:500px", "g"), "max-width:500px");
        } catch (error) { console.log(error, this.userInfo.richIntro) }
      }
      return handleRichIntro;
    },
    // 判断是否有完善的个人信息，没有的话，显示占位图
    showPlaceholder() {
      let showPlaceholder = false;
      if (this.handleIntro.length === 0 && this.userInfo.likeCount === "0" && this.userInfo.commentCount === "0") {
        showPlaceholder = true
      }
      return showPlaceholder;
    }
  },
  data() {
    return {
      // 开始评论输入框获取焦点
      commentInput: false,
      // 是否在回复子评论
      isReplayChild: false,
      // 被回复的子评论信息
      relpalChildObj: {},
      // 回复的内容
      replayContent: "",
    }
  },
  watch: {
    commentInput: {
      handler(val, old) {
        if (val && val !== old) {
          this.$forceUpdate();
          this.$nextTick(() => {
            this.$refs.commentInput.focus();
          })
        }
      },
      deep: true
    }
  },
  methods: {
    formartImageUrl,
    // 点击跳转用户详情页面
    handlerToUser(id) {
      this.$router.push({
        name: "user",
        params: { id: "pai_" + Base64.encode(id) }
      });
    },
    // 点击回复个人中心
    handlerEmitReplay() {
      this.relpalChildObj = {};
      this.isReplayChild = false;
      this.$nextTick(() => {
        this.startComment();
      })
    },
    // 点击回复个人中心子评论
    handlerEmitReplayChild(obj) {
      this.isReplayChild = true;
      this.relpalChildObj = obj;
      this.$nextTick(() => {
        this.startComment();
      })
    },
    // 打开评论输入框
    startComment() {
      if (this.hasLogin) {
        this.commentInput = true;
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },
    // 点击发送评论
    startSubmitComment() {
      let replayContent = this.replayContent;
      if (!this.isReplayChild) {
        this.handlerReplayFunc(replayContent)
      } else {
        this.handlerReplayChild(replayContent)
      }
    },
    // 开始发送主评论
    async handlerReplayFunc(replayContent) {
      const params = {
        reply: replayContent,
        language: localStorage.getItem("langCode") || "1",
        operationId: this.userInfo.providerId,
        operation: 3,
        userId: this.$store.state.User.userinfo.id,
        reviewId: ""
      };
      const result = await replayViews(params).catch(e => { });
      if (result && result.data) {
        this.replayContent = "";
        this.$nextTick(() => {
          this.$refs.comments.getComments();
        })
      }
    },
    // 开始发送对评论的评论
    async handlerReplayChild() {
      const loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        const params = {
          ...this.relpalChildObj,
          reply: this.replayContent,
          userId: loginUser.id,
          token: loginUser.token,
          language: localStorage.getItem("langCode") || "1"
        };
        const result = await replayViews(params).catch(e => { });
        if (result && result.code == 200) {
          this.replayContent = "";
          this.relpalChildObj = {};
          this.isReplayChild = false;
          this.$nextTick(() => {
            this.$refs.comments.getComments();
          })
        }
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },
    // 输入框失去焦点，关闭输入悬窗
    commentInputBlur() {
      // 这里的延迟是为了上面发送评论时获取到输入的评论内容
      window.requestAnimationFrame(() => {
        this.commentInput = false;
      })
    },
    // 点击喜欢按钮
    startLike() {
      if (this.hasLogin) {
        this.changeLikeStatus();
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },
    // 改变喜欢的状态
    async changeLikeStatus() {
      let params = {
        userId: this.$store.state.User.userinfo.id,
        language: localStorage.getItem("langCode") || "1",
        action: !this.userInfo.likeStatus,
        type: 3
      };
      let url = `rest/home/like/${this.userInfo.providerId}`
      let result = await this.$http({
        url,
        params,
        withCredentials: false,
        method: "post"
      }).catch(e => { });
      if (result && result.code === 201) {
        this.$emit("handlerGetUserinfo");
      } else {
        console.log(result.message)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .personalprofile
    width 100%;
    margin-top 8px;
    min-height 0;
    overflow hidden;
    background #FFFFFF;
    .introBox
      width 100%;
      padding 9px;
      box-sizing border-box;
    .richIntroBox
      white-space pre-wrap;
    .likeBox
      width 100%;
      padding 0 9px;
      box-sizing border-box;
      .likeStatistics
        width 100%;
        height 51px;
        border-bottom 1px solid #E8E8E8;
        display flex;
        align-items center;
        justify-content flex-start;
        .likeIcon
          width 30px;
          height 30px;
          display flex;
          align-items center;
          justify-content center;
          .iconStlye
            font-size 30px;
        .likeCount
          color #1A051D;
          font-size 14px;
          margin-left 3px;
      .likePeopleBox
        width 100%;
        box-sizing border-box;
        padding 17px 0;
        .likeItemsLine
          width 100%;
          height 48px;
          display flex;
          align-items center;
          margin-bottom 15px;
          &:last-child
            margin-bottom 0;
          .likeItem
            width 48px;
            height 100%;
            overflow hidden;
    .commentBox
      width 100%;
      padding 0 9px;
      box-sizing border-box;
      .commentStatistics
        width 100%;
        height 51px;
        border-bottom 1px solid #E8E8E8;
        display flex;
        align-items center;
        justify-content flex-start;
        .commentIcon
          width 30px;
          height 30px;
          display flex;
          align-items center;
          justify-content center;
          .iconStlye
            font-size 30px;
        .commentCount
          color #1A051D;
          font-size 14px;
          margin-left 3px;
    .operatingFlexBox
      position fixed;
      width 100%;
      height 80px;
      bottom 16px;
      left 0px;
      display flex;
      align-items center;
      justify-content flex-end;
      .commonButtonStyle
        height 44px;
        display flex;
        align-items center;
        border-radius 22px;
        justify-content center;
      .commentButton
        padding 0 8px 0 4px;
        background #33CC66;
        box-shadow: 0px 6px 12px 0px #7fd59b;
        display flex;
        align-items center;
        margin-right 15px;
        position relative;
        .loginPerson
          height 36px;
          width 36px;
          border-radius 50%;
          overflow hidden;
          .notLoggedIn
            width 100%;
            height 100%;
            background #F2F2F2;
            display flex;
            align-items center;
            justify-content center;
            .iconStyle
              font-size 24px;
              color #666666;
        .iconBox
          height 36px;
          overflow hidden;
          display flex;
          align-items center;
          justify-content center;
          transition width .3s;
          .iconStyle
            font-size 20px;
            color #FFFFFF;
          .inputerAndSendBox
            width 100%;
            height 100%;
            display flex;
            align-items center;
            justify-content flex-start;
            .commentInput
              border none;
              background transparent;
              flex 1;
              min-width 0;
              color #FFFFFF;
              font-size 14px;
              box-sizing border-box;
              padding 0 5px;
            .sendComment
              height 30px;
              padding 0 15px;
              border-radius 15px;
              margin-left 5px;
              display flex;
              align-items center;
              justify-content center;
              background #f2f2f2;
              .sendStyle
                font-size 20px;
                color #999999;
      .likeButton
        width 44px;
        margin-right 17px;
        background #F9F9F9;
        box-sizing border-box;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 6px 12px 0px rgba(146,146,146,0.31);
        .iconStyle
          font-size 26px;


  .deleteClass
    height: 26rem
    display: flex
    justify-content: center;
    align-items: center
    background: #fff;
</style>
<style lang="stylus">
  @import './personalprofile'
  .personalprofile
    img
      max-width 100% !important;
    video
      max-width 100% !important;

</style>