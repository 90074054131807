var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "placeholderMap" },
    [
      _c("van-image", {
        attrs: {
          width: "166px",
          height: "143px",
          src: require("../../../../assets/images/newUserInfo/placeholder.png")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }