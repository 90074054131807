<template>
  <div class="placeholderMap">
    <van-image
      width="166px"
      height="143px"
      :src="require('../../../../assets/images/newUserInfo/placeholder.png')"
    ></van-image>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="stylus" scoped>
  .placeholderMap
    width 100%;
    padding 87px 0 186px 0;
    display flex;
    align-items center;
    justify-content center;
    background #FFFFFF;

</style>