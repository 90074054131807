<template>
  <div class="personalGroupList">
    <van-list
      v-model="groupLoading"
      :finished="groupFinished"
      finished-text="---end---"
      @load="onLoad"
      v-if="groupInfoList.length !== 0"
    >
      <van-cell v-for="item in groupInfoList" :key="item.groupId">
        <div class="groupItem" @click.stop="jumpGroupPage(item.groupId)">
          <div class="groupHeader">
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              :src="formartImageUrl(item.groupHeader)"
            >
              <template v-slot:error>Melinked</template>
            </van-image>
            <div class="groupType" :style="groupTypeStyle(item.accessPattern)">
              <i class="iconfont_Me" :class="groupIcon(item.accessPattern)"></i>
            </div>
          </div>
          <div class="groupInfo">
            <div class="groupName">
              <div class="textBox">
                {{item.groupName}}
              </div>
              <div class="iconBox">
                <i class="iconfont_Me icon_qunzhu iconStyle"></i>
              </div>
            </div>
            <div class="groupDescribe">
              {{groupInfo(item.groupInfo)}}
            </div>
            <div class="groupStatistics">
              <div class="iconBox">
                <i class="iconfont_Me iconStyle icon_group-fill"></i>
              </div>
              <div class="statisticsText">
                {{item.memberCount}}
              </div>
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
    <PlaceholderMap v-else></PlaceholderMap>
  </div>
</template>
<script>
import { formartImageUrl } from "@/utils/help";
import PlaceholderMap from "../common/placeholderMap";
export default {
  props: {
    // 群组列表
    groupInfoList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  components: {
    PlaceholderMap
  },
  data() {
    return {
      // 加载完成，没有更多数据
      groupFinished: false,
      // 加载loading
      groupLoading: false,
    }
  },
  methods: {
    formartImageUrl,
    // 将群描述过滤掉HTML标签和转义符
    groupInfo(groupInfo) {
      return groupInfo.replace(/<.*?>/g,"").replace(/&lt;.*?&gt;/g,"");
    },
    // 跳转到群详情页面
    jumpGroupPage(id) {
      this.$router.push({ 
        name: 'group', 
        params: { id: "pai_" + Base64.encode(id) } 
      })
    },
    // 根据群类型设置群类型样式
    groupTypeStyle(accessPattern) {
      let groupTypeStyle = new Object();
      switch (accessPattern) {
        case 1:
          groupTypeStyle.color="#3A0E40";
          groupTypeStyle.border="1px solid #3A0E40";
          break;
        case 2:
          groupTypeStyle.color="#FFBD07";
          groupTypeStyle.border="1px solid #FFBD07";
          break;
        case 3:
          groupTypeStyle.color="#33CC66";
          groupTypeStyle.border="1px solid #33CC66";
          break;
      }
      return groupTypeStyle;
    },
    // 根据群类型设置群类型类名
    groupIcon(accessPattern) {
      let groupIcon;
      switch (accessPattern) {
        case 1:
          groupIcon="icon_open-fill"
          break;
        case 2:
          groupIcon="icon_member-fill"
          break;
        case 3:
          groupIcon="icon_no_eye"
          break;
      }
      return groupIcon
    },
    // 加载更多
    onLoad() {
      this.$emit("loadMoreGroup",(response) => {
        switch (response) {
          case "complete":
            this.groupLoading = false;
            break;
          case "finished":
            this.groupFinished = true;
            break;
        }
      });
    }
  }
}
</script>
<style lang="stylus" scoped>
  .personalGroupList
    width 100%;
    margin-top 8px;
    min-height 0;
    overflow hidden;
    background #FFFFFF;
    .groupItem
      width 100%;
      height 78px;
      margin-top 20px;
      overflow hidden;
      display flex;
      align-items center;
      justify-content flex-start;
      .groupHeader
        width 78px;
        height 78px;
        flex-shrink 0;
        background #f7f8fa;
        overflow hidden;
        position relative;
        .groupType
          top 5px;
          right 5px;
          width 18px;
          height 18px;
          z-index 2;
          border-radius 4px;
          position absolute;
          box-sizing border-box;
          background #FFFFFF;
          display flex;
          align-items center;
          justify-content center;
      .groupInfo
        flex 1;
        height 78px;
        min-width 0;
        box-sizing border-box;
        padding-left 17px;
        .groupName
          width 100%;
          height 22px;
          display flex;
          align-items center;
          justify-content flex-start;
          .textBox
            min-width 0;
            height 100%;
            max-width 100%;
            text-align left;
            line-height 22px;
            font-size 14px;
            font-weight bold;
            color #333333;
            overflow hidden;
            text-overflow ellipsis;
            white-space nowrap;
          .iconBox
            width 20px;
            height 20px
            flex-shrink 0;
            margin-left 5px;
            display flex;
            align-items center;
            justify-content center;
            .iconStyle
              font-size 16px;
              color #33CC66;
        .groupDescribe
          width 100%;
          height 18px;
          margin-top 6px;
          line-height 18px;
          font-size 14px;
          color #666666;
          text-align left;
          overflow hidden;
          text-overflow ellipsis;
          white-space nowrap;
        .groupStatistics
          width 100%;
          height 18px;
          margin-top 10px;
          display flex;
          align-items center;
          justify-content flex-start;
          .iconBox
            width 18px;
            height 18px;
            flex-shrink 0;
            display flex;
            align-items center;
            justify-content center;
            .iconStyle
              font-size 18px;
              color #999999;
          .statisticsText
            flex 1;
            min-width 0;
            height 18px;
            line-height 18px;
            box-sizing border-box;
            padding-left 5px;
            font-size 12px;
            color #666666;
            text-align left

</style>
<style lang="stylus">
  .personalGroupList
    .van-loading 
      height 100% !important;
      padding-top 0 !important;
      align-items center !important;
    .van-list
      .van-cell
        padding 0 16px !important;
        &:after
          border-bottom none !important;

</style>