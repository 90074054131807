var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personalPostList" },
    [
      _c("div", { staticClass: "listHeaderBox" }, [
        _c("div", { staticClass: "totalBox" }, [
          _c("i", { staticClass: "iconfont_Me icon_Post iconStyle" }),
          _vm._v("\n      " + _vm._s("(" + _vm.postListTotal + ")") + "\n    ")
        ]),
        _c(
          "div",
          {
            staticClass: "sortButton",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.changePostSort($event)
              }
            }
          },
          [
            _c("van-image", {
              attrs: {
                width: "100%",
                height: "100%",
                fit: "cover",
                src: _vm.sortImage
              }
            })
          ],
          1
        )
      ]),
      _vm.postInfoList.length !== 0
        ? _c(
            "van-list",
            {
              attrs: {
                finished: _vm.postFinished,
                "finished-text": "---end---"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.postLoading,
                callback: function($$v) {
                  _vm.postLoading = $$v
                },
                expression: "postLoading"
              }
            },
            _vm._l(_vm.postInfoList, function(item) {
              return _c(
                "van-cell",
                { key: item.gigId },
                [
                  _c("PostItem", {
                    attrs: { postInfo: item, categories: _vm.categories }
                  })
                ],
                1
              )
            }),
            1
          )
        : _c("PlaceholderMap")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }