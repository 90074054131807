var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.userInfo.isDelete == 3
      ? _c("div", { staticClass: "deleteClass" }, [
          _c("img", {
            attrs: {
              src: require("../../../../assets/images/newUserInfo/placeholder.png"),
              alt: ""
            }
          })
        ])
      : _c(
          "div",
          { staticClass: "personalprofile" },
          [
            _vm.handleIntro.length !== 0 && _vm.handleRichIntro === ""
              ? _c(
                  "div",
                  { staticClass: "introBox" },
                  _vm._l(_vm.handleIntro, function(item, index) {
                    return _c("DisplayComponent", {
                      key: index,
                      attrs: { item: item }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm.handleRichIntro !== ""
              ? _c("div", {
                  staticClass: "introBox richIntroBox",
                  domProps: { innerHTML: _vm._s(_vm.handleRichIntro) }
                })
              : _vm._e(),
            _vm.userInfo.likeCount !== "0"
              ? _c("div", { staticClass: "likeBox" }, [
                  _c("div", { staticClass: "likeStatistics" }, [
                    _c("div", { staticClass: "likeIcon" }, [
                      _c("i", {
                        staticClass: "iconfont_Me icon_fabulous iconStlye",
                        style: { color: "#200E32" }
                      })
                    ]),
                    _c("div", { staticClass: "likeCount" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.likeCount) +
                          "\n        "
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "likePeopleBox" },
                    _vm._l(_vm.userInfo.likeProviders, function(
                      fatherItem,
                      fatherIndex
                    ) {
                      return _c(
                        "div",
                        {
                          key: fatherIndex,
                          staticClass: "likeItemsLine",
                          style: {
                            justifyContent:
                              fatherItem.length && fatherItem.length < 6
                                ? "flex-start"
                                : "space-between"
                          }
                        },
                        _vm._l(fatherItem, function(childItem, childIndex) {
                          return _c(
                            "div",
                            {
                              key: childIndex,
                              staticClass: "likeItem",
                              style: {
                                marginRight:
                                  fatherItem.length && fatherItem.length < 6
                                    ? "21px"
                                    : "0"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handlerToUser(childItem.providerId)
                                }
                              }
                            },
                            [
                              _c("AvatarBox", {
                                attrs: {
                                  src: childItem.userAvatar,
                                  country: childItem.country.toString(),
                                  nationalFlagSize: 16
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm.userInfo.commentCount !== "0"
              ? _c(
                  "div",
                  { staticClass: "commentBox" },
                  [
                    _c("div", { staticClass: "commentStatistics" }, [
                      _c("div", { staticClass: "commentIcon" }, [
                        _c("i", {
                          staticClass: "iconfont_Me icon_comment iconStlye",
                          style: {
                            color: "#200E32"
                          }
                        })
                      ]),
                      _c("div", { staticClass: "commentCount" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.userInfo.commentCount) +
                            "\n        "
                        )
                      ])
                    ]),
                    _c("Comments", {
                      ref: "comments",
                      attrs: {
                        id: _vm.userInfo.providerId,
                        count: _vm.userInfo.commentCount,
                        showCount: false,
                        type: "user",
                        noBottomBorder: true
                      },
                      on: { replayChild: _vm.handlerEmitReplayChild }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showPlaceholder ? _c("PlaceholderMap") : _vm._e(),
            _c("div", { staticClass: "operatingFlexBox" }, [
              _c("div", { staticClass: "commonButtonStyle commentButton" }, [
                _c(
                  "div",
                  { staticClass: "loginPerson" },
                  [
                    _vm.loginUserImage === ""
                      ? _c("div", { staticClass: "notLoggedIn" }, [
                          _c("i", {
                            staticClass: "iconStyle iconfont_Me icon_user-fill"
                          })
                        ])
                      : _c("van-image", {
                          attrs: {
                            width: "100%",
                            height: "100%",
                            fit: "cover",
                            src: _vm.loginUserImage
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "iconBox",
                    style: { width: _vm.commentInput ? "200px" : "50px" }
                  },
                  [
                    _vm.commentInput
                      ? _c("div", { staticClass: "inputerAndSendBox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.replayContent,
                                expression: "replayContent"
                              }
                            ],
                            ref: "commentInput",
                            staticClass: "commentInput",
                            attrs: { type: "text" },
                            domProps: { value: _vm.replayContent },
                            on: {
                              blur: function($event) {
                                $event.stopPropagation()
                                return _vm.commentInputBlur($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.replayContent = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "sendComment",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.startSubmitComment($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sendStyle iconfont_Me icon_send"
                              })
                            ]
                          )
                        ])
                      : _c("i", {
                          staticClass: "iconStyle iconfont_Me icon_edit1",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handlerEmitReplay($event)
                            }
                          }
                        })
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "commonButtonStyle likeButton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.startLike($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "iconStyle iconfont_Me",
                    class: _vm.userInfo.likeStatus
                      ? "icon_fabulous-fill"
                      : "icon_fabulous",
                    style: {
                      color: _vm.userInfo.likeStatus ? "#fe0657" : "#200E32"
                    }
                  })
                ]
              )
            ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }