var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userInfoBox" },
    [
      _c(
        "Header",
        {
          ref: "pageHeader",
          attrs: { headerBackground: "#FFFFFF", headerHeight: 60 }
        },
        [
          _c("i", {
            staticClass: "iconfont_Me icon_return",
            style: { color: "#333333" },
            attrs: { slot: "left-one" },
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            },
            slot: "left-one"
          })
        ]
      ),
      _c(
        "div",
        {
          staticClass: "headerBox",
          style: { paddingTop: _vm.pageDisplacement }
        },
        [
          _c("div", { staticClass: "firstLineBox" }, [
            _c(
              "div",
              {
                staticClass: "userImage",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.previewAvatar($event)
                  }
                }
              },
              [
                _c("AvatarBox", {
                  attrs: {
                    src:
                      _vm.userInfo.isDelete == 3
                        ? ""
                        : _vm.userInfo.profilePhoto,
                    country: _vm.userInfo.country
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "operateBox" }, [
              _c(
                "div",
                {
                  staticClass: "chatButton commonButton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.jumpChatWithPage($event)
                    }
                  }
                },
                [
                  _c("van-image", {
                    attrs: {
                      width: "24px",
                      height: "24px",
                      fit: "cover",
                      src: require("../../assets/images/newUserInfo/chatIcon.png")
                    }
                  })
                ],
                1
              ),
              false
                ? _c(
                    "div",
                    { staticClass: "editButton commonButton" },
                    [
                      _c("van-image", {
                        attrs: {
                          width: "24px",
                          height: "24px",
                          fit: "cover",
                          src: require("../../assets/images/newUserInfo/edit.png")
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.userInfo.isDelete != 3,
                  expression: "userInfo.isDelete != 3"
                }
              ],
              staticClass: "secondLineBox commonLine"
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "textStyle" }, [
                _vm._v("\n        " + _vm._s(_vm.userInfo.name) + "\n      ")
              ]),
              _c(
                "div",
                { staticClass: "genderBox" },
                [
                  _c("van-image", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      fit: "cover",
                      src:
                        _vm.userInfo.gender === "male"
                          ? require("../../assets/images/newUserInfo/male.png")
                          : _vm.userInfo.gender === "female"
                          ? require("../../assets/images/newUserInfo/female.png")
                          : ""
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "thirdLineBox commonLine" }, [
            _c("div", { staticClass: "iconBox fontStyle" }, [_vm._v("ID")]),
            _c("div", { staticClass: "textStyle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.userInfo.isDelete == 3
                      ? _vm.userInfo.providerId
                      : _vm.userInfo.ucId
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm.userInfo.joinTime
            ? _c("div", { staticClass: "thirdLineBox commonLine" }, [
                _c(
                  "div",
                  { staticClass: "iconBox" },
                  [
                    _c("van-image", {
                      attrs: {
                        width: "100%",
                        height: "100%",
                        fit: "cover",
                        src: require("../../assets/images/newUserInfo/createTime.png")
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "textStyle" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.userInfo.joinTime) + "\n      "
                  )
                ])
              ])
            : _vm._e(),
          _vm.skills.length !== 0
            ? _c(
                "div",
                { staticClass: "skillsBox" },
                _vm._l(_vm.skills, function(item, index) {
                  return _c("div", { key: index, staticClass: "skillItem" }, [
                    _vm._m(1, true),
                    _c("div", { staticClass: "skillContent" }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _c("van-sticky", { attrs: { "offset-top": _vm.pageDisplacement } }, [
        _c(
          "div",
          { staticClass: "tabbarBox" },
          _vm._l(_vm.tabbars, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tabbarItem",
                style: {
                  borderBottom: item.isSelected ? "1px solid #333333" : ""
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.selectedTabbar(index)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "iconfont_Me iconStyle",
                  class: item.className,
                  style: { color: item.isSelected ? "#333333" : "#999999" }
                })
              ]
            )
          }),
          0
        )
      ]),
      _c("Personalprofile", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tabbarIndex === 0,
            expression: "tabbarIndex === 0"
          }
        ],
        attrs: { userInfo: _vm.userInfo },
        on: { handlerGetUserinfo: _vm.handlerGetUserinfo }
      }),
      _c("PersonalGroupList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tabbarIndex === 1,
            expression: "tabbarIndex === 1"
          }
        ],
        attrs: { groupInfoList: _vm.groupInfoList },
        on: { loadMoreGroup: _vm.loadMoreGroup }
      }),
      _c("PersonalPostList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tabbarIndex === 2,
            expression: "tabbarIndex === 2"
          }
        ],
        attrs: {
          postInfoList: _vm.postInfoList,
          postListTotal: _vm.postListTotal,
          categories: _vm.categories
        },
        on: {
          loadMorePost: _vm.loadMorePost,
          changePostSort: _vm.changePostSort
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("i", { staticClass: "iconfont_Me icon_user-fill" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "skillIcon" }, [
      _c("i", { staticClass: "iconfont_Me icon_tag2 iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }