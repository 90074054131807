<template>
  <div class="personalPostList">
    <div class="listHeaderBox">
      <div class="totalBox">
        <i class="iconfont_Me icon_Post iconStyle"></i>
        {{`(${postListTotal})`}}
      </div>
      <div class="sortButton" @click.stop="changePostSort">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          :src="sortImage"
        />
      </div>
    </div>
    <van-list
      v-model="postLoading"
      :finished="postFinished"
      finished-text="---end---"
      @load="onLoad"
      v-if="postInfoList.length !== 0"
    >
      <van-cell v-for="item in postInfoList" :key="item.gigId">
        <PostItem :postInfo="item" :categories="categories"></PostItem>
      </van-cell>
    </van-list>
    <PlaceholderMap v-else></PlaceholderMap>
  </div>
</template>
<script>
import PostItem from "../common/postItem";
import PlaceholderMap from "../common/placeholderMap";
export default {
  components: {
    PostItem,
    PlaceholderMap
  },
  props: {
    // 帖子列表
    postInfoList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 帖子总数
    postListTotal: {
      type: String,
      default: "0"
    },
    // 帖子类型
    categories: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // 帖子列表的展示顺序（desc:倒序；asc:正序）
      sort: "desc",
      // 加载完成，没有更多数据
      postFinished: false,
      // 加载loading
      postLoading: false,
    }
  },
  computed: {
    // 顺序图标
    sortImage() {
      let sortImage;
      // 倒序列表的时候显示正序图标，正序按钮的时候显示倒序图标
      switch (this.sort) {
        case "desc":
          sortImage = require("../../../../assets/images/newUserInfo/timeSort_asc.png");
          break;
        case "asc":
          sortImage = require("../../../../assets/images/newUserInfo/timeSort_desc.png");
          break;
      }
      return sortImage;
    }
  },
  methods: {
    // 改变列表排列顺序
    changePostSort() {
      if(this.sort === "desc") {
        this.sort = "asc"
      } else {
        this.sort = "desc"
      }
      this.$emit("changePostSort",this.sort,()=>{
        this.postFinished = false;
        this.postLoading = false;
      })
    },
    // 加载更多数据
    onLoad() {
      this.$emit("loadMorePost",(response) => {
        switch (response) {
          case "complete":
            this.postLoading = false;
            break;
          case "finished":
            this.postFinished = true;
            break;
        }
      });
    }
  }
}
</script>
<style lang="stylus" scoped>
  .personalPostList
    width 100%;
    min-height 0;
    overflow hidden;
    .listHeaderBox
      height 40px;
      width 100%;
      padding 0 16px;
      box-sizing border-box;
      background #F3F3F3;
      display flex;
      align-items center;
      justify-content space-between;
      .totalBox
        height 100%;
        display flex;
        color #919191;
        font-size 14px;
        align-items center;
        justify-content flex-start;
        .iconStyle
          color #919191;
          font-size 20px;
          margin-right 5px;
      .sortButton
        width 24px;
        height 24px;

</style>
<style lang="stylus">
  .personalPostList
    .van-loading 
      height 100% !important;
      padding-top 0 !important;
      align-items center !important;
    .van-list
      .van-cell
        margin-top 10px !important;
        padding 0 16px !important;
        &:after
          border-bottom none !important;

</style>