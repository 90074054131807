<template>
  <div class="userInfoBox">
    <Header ref="pageHeader" :headerBackground="'#FFFFFF'" :headerHeight="60">
      <i slot="left-one" class="iconfont_Me icon_return" :style="{ color: '#333333' }" @click="$router.go(-1)"></i>
    </Header>
    <div class="headerBox" :style="{ paddingTop: pageDisplacement }">
      <div class="firstLineBox">
        <div class="userImage" @click.stop="previewAvatar">

          <AvatarBox :src="userInfo.isDelete == 3 ? '' : userInfo.profilePhoto" :country="userInfo.country"></AvatarBox>
        </div>
        <div class="operateBox">
          <div class="chatButton commonButton" @click.stop="jumpChatWithPage">
            <van-image width="24px" height="24px" fit="cover"
              :src="require('../../assets/images/newUserInfo/chatIcon.png')" />
          </div>
          <!-- <div class="commonButton" v-if="userInfo.providerId !== $store.state.User.userinfo.id" @click.stop="changeFriendStatus">
            <i class="iconStyle iconfont_Me" :class="userInfo.friendStatus?'icon_deleteuser-fill':'icon_Adduser-fill'"></i>
          </div> -->
          <div class="editButton commonButton" v-if="false">
            <van-image width="24px" height="24px" fit="cover"
              :src="require('../../assets/images/newUserInfo/edit.png')" />
          </div>
        </div>
      </div>
      <div class="secondLineBox commonLine" v-show="userInfo.isDelete != 3">
        <div class="iconBox">
          <i class="iconfont_Me icon_user-fill"></i>
        </div>
        <div class="textStyle">
          {{ userInfo.name }}
        </div>
        <div class="genderBox">
          <van-image width="100%" height="100%" fit="cover"
            :src="userInfo.gender === 'male' ? require('../../assets/images/newUserInfo/male.png') : userInfo.gender === 'female' ? require('../../assets/images/newUserInfo/female.png') : ''" />
        </div>
      </div>
      <div class="thirdLineBox commonLine">
        <div class="iconBox fontStyle">ID</div>
        <div class="textStyle">
          {{ userInfo.isDelete == 3 ? userInfo.providerId : userInfo.ucId }}
        </div>
      </div>
      <div class="thirdLineBox commonLine" v-if="userInfo.joinTime">
        <div class="iconBox">
          <van-image width="100%" height="100%" fit="cover"
            :src="require('../../assets/images/newUserInfo/createTime.png')" />
        </div>
        <div class="textStyle">
          {{ userInfo.joinTime }}
        </div>
      </div>
      <div class="skillsBox" v-if="skills.length !== 0">
        <div class="skillItem" v-for="(item, index) in skills" :key="index">
          <div class="skillIcon">
            <i class="iconfont_Me icon_tag2 iconStyle"></i>
          </div>
          <div class="skillContent">{{ item }}</div>
        </div>
      </div>
    </div>
    <van-sticky :offset-top="pageDisplacement">
      <div class="tabbarBox">
        <div class="tabbarItem" v-for="(item, index) in tabbars" :key="index"
          :style="{ borderBottom: item.isSelected ? '1px solid #333333' : '' }" @click.stop="selectedTabbar(index)">
          <i class="iconfont_Me iconStyle" :class="item.className"
            :style="{ color: item.isSelected ? '#333333' : '#999999' }"></i>
        </div>
      </div>
    </van-sticky>
    <Personalprofile v-show="tabbarIndex === 0" :userInfo="userInfo" @handlerGetUserinfo="handlerGetUserinfo">
    </Personalprofile>
    <PersonalGroupList v-show="tabbarIndex === 1" :groupInfoList="groupInfoList" @loadMoreGroup="loadMoreGroup">
    </PersonalGroupList>
    <PersonalPostList v-show="tabbarIndex === 2" :postInfoList="postInfoList" :postListTotal="postListTotal"
      :categories="categories" @loadMorePost="loadMorePost" @changePostSort="changePostSort"></PersonalPostList>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import { Base64 } from "js-base64";
import AvatarBox from "../../components/business/newVersion/common/avatarBox";
import Personalprofile from "@/components/business/newVersion/userinfo/personalprofile";
import PersonalGroupList from "@/components/business/newVersion/userinfo/personalGroupList";
import PersonalPostList from "@/components/business/newVersion/userinfo/personalPostList";
// 图片预览
import { ImagePreview } from 'vant';
import {
  getGroupListData,
  getPostListData,
  getCategories,
  getPersonnelDetails,
} from "@/api/webApi";
export default {
  components: {
    Header,
    Personalprofile,
    PersonalGroupList,
    PersonalPostList,
    AvatarBox,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      // 页面整体向下位移距离
      pageDisplacement: "0px",
      // 用户的ID
      userId: "",
      // 当前语言
      language: "",
      // 用户的基本信息
      userInfo: {},
      // 已选中的tabbar下标
      tabbarIndex: 0,
      // 群组列表当前展示页的页码
      groupPageNum: 1,
      // 群组列表数据
      groupInfoList: [],
      // 帖子列表当前展示页的页码
      postPageNum: 1,
      // 帖子列表数据
      postInfoList: [],
      // 帖子总数
      postListTotal: '0',
      // 帖子展示顺序（默认倒序）
      timeSort: "desc",
      // 类型集合
      categories: []
    }
  },
  computed: {
    // 判断是否登录
    hasLogin() {
      let hasLogin = false;
      let loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        hasLogin = true
      }
      return hasLogin;
    },
    // tab展示的按钮
    tabbars() {
      let tabbars = [
        {
          className: "icon_businesscard1",
          isSelected: false
        },
        {
          className: "icon_group",
          isSelected: false
        },
        {
          className: "icon_Post",
          isSelected: false
        },
      ]
      tabbars = tabbars.map((item, index) => {
        if (index === this.tabbarIndex) {
          item.isSelected = true
        }
        return item
      })
      return tabbars;
    },
    // 用户标签
    skills() {
      let skills = [];
      if (this.userInfo.skills && this.userInfo.skills !== '') {
        skills = this.userInfo.skills.split(',')
      }
      return skills;
    }
  },
  async mounted() {
    try {
      if (this.$route.params.id.indexOf("pai_") !== -1) {
        this.userId = Base64.decode(this.$route.params.id.replace("pai_", ""));
      } else {
        this.userId = this.$route.params.id;
      }
    } catch (e) { }
    this.language = this.$route.params.language || 1;
    localStorage.setItem("langCode", this.language);
    await this.handlerGetCategories();
    this.$nextTick(() => {
      // 获取header组件的高度
      this.pageDisplacement = `${this.$refs.pageHeader.$el.clientHeight}px`;
      this.handlerGetUserinfo();
    })
  },
  watch: {
    "$route.params.id": {
      handler(val, old) {
        if (val !== old) {
          window.location.reload();
        }
      },
      deep: true,
    },
    // 监听选中的tab下标
    tabbarIndex: {
      handler(val, old) {
        if (val !== old) {
          switch (val) {
            case 0:
              this.handlerGetUserinfo();
              break;
            case 1:
              this.handlerGetUserGroups();
              break;
            case 2:
              this.handlerGetUserPosts();
              break;
          }
        }
      },
      deep: true
    }
  },
  methods: {
    // 预览头像
    previewAvatar() {
      ImagePreview({
        images: [this.userInfo.profilePhoto],
        startPosition: 0,
      });
    },
    // 改变好友状态
    changeFriendStatus() {
      if (this.userInfo.friendStatus) {
        this.deleteFriend();
      } else {
        this.modifyFriendInformation();
      }
    },
    // 删除好友
    async deleteFriend() {
    },
    // 添加好友，显示弹窗
    modifyFriendInformation() {
    },
    // 点击选择tab
    selectedTabbar(index) {
      this.tabbarIndex = index;
      this.$forceUpdate();
    },
    // 点击前往聊天页面事件
    jumpChatWithPage() {
      this.$router.push({
        name: 'chatWith',
        params: {
          id: "pai_" + Base64.encode(this.userId),
          name: this.userInfo.name,
          type: 'chat'
        }
      })
    },
    // 获取用户信息数据
    async handlerGetUserinfo() {
      let result = await getPersonnelDetails(this.userId).catch(e => { });
      if (result.code == 200) {
        this.handlerGetLikePeople(result.data.data.likeProviders).then((handleLikeProviders) => {
          result.data.data.likeProviders = handleLikeProviders;
          this.userInfo = result.data.data;
        })
      } else {
        console.log(result.message)
      }
    },
    // 排列点赞列表
    handlerGetLikePeople(likeProviders) {
      return new Promise(async (resolve) => {
        let handleLikeProviders = []
        // 将人员分成六个一组
        for (let i = 0; i < likeProviders.length; i += 6) {
          handleLikeProviders.push(likeProviders.slice(i, i + 6));
        }
        resolve(handleLikeProviders)
      })
    },
    // 加载更多群组列表
    async loadMoreGroup(car) {
      this.groupPageNum++;
      this.handlerGetUserGroups().then((response) => car(response));
    },
    // 获取这个用户的群组列表
    handlerGetUserGroups() {
      return new Promise(async (resolve) => {
        const params = {
          providerId: this.userId,
          groupMode: "all",
          page: this.groupPageNum,
          pageSize: 20
        };
        const result = await getGroupListData(this.userId, params).catch(e => { });
        if (result.code === 200) {
          if (result.data.data.length !== 0) {
            // 当时请求的第一页的时候，清空列表数据
            if (this.groupPageNum === 1) {
              this.groupInfoList = [];
            }
            this.groupInfoList = this.groupInfoList.concat(result.data.data);
            // 过滤一次,防止有相同的数据出现
            this.groupInfoList = this.filterAnArrayOfObjects(this.groupInfoList, 'groupId');
            this.$nextTick(() => {
              resolve("complete")
            })
          } else if (this.groupPageNum > 1) {
            this.groupPageNum--;
            this.$nextTick(() => {
              resolve("finished")
            })
          }
        } else {
          console.log(result.message)
        }
      })
    },
    // 加载更多帖子数据
    async loadMorePost(car) {
      this.postPageNum++;
      this.handlerGetUserPosts().then((response) => car(response));
    },
    // 改变帖子列表的排列顺序
    async changePostSort(timeSort, car) {
      this.postPageNum = 1;
      this.timeSort = timeSort;
      this.handlerGetUserPosts().then((response) => car(response));
    },
    // 获取这个用户的帖子列表
    handlerGetUserPosts() {
      return new Promise(async (resolve) => {
        const params = {
          providerId: this.userId,
          page: this.postPageNum,
          pageSize: 20,
          timeSort: this.timeSort
        };
        const result = await getPostListData(this.userId, params).catch(e => { });
        if (result.code == 200) {
          if (result.data.data.length !== 0) {
            // 当时请求的第一页的时候，清空列表数据
            if (this.postPageNum === 1) {
              this.postInfoList = [];
            }
            this.postInfoList = this.postInfoList.concat(result.data.data);
            // 过滤一次,防止有相同的数据出现
            this.postInfoList = this.filterAnArrayOfObjects(this.postInfoList, 'gigId');
            this.postListTotal = result.data.total;
            this.$nextTick(() => {
              resolve("complete")
            })
          } else if (this.postPageNum > 1) {
            this.postPageNum--;
            this.$nextTick(() => {
              resolve("finished")
            })
          }
        } else {
          console.log(result.message)
        }
      })
    },
    // 获取帖子
    async handlerGetCategories() {
      return new Promise(async (resolve) => {
        let params = {
          language: this.language
        };
        const result = await getCategories(params);
        if (result) {
          this.categories = result.data.data.map(item => {
            return {
              text: item.category_name,
              value: item.category_id
            };
          });
          resolve("success")
        }
      })
    },
    // 用于过滤对象数组中重复的元素(传入需要过滤的数组，以及用于对比的键名)
    filterAnArrayOfObjects(array, key) {
      let hash = {};
      let newArr = array.reduce((item, next) => {
        hash[next[key]] ? '' : hash[next[key]] = true && item.push(next);
        return item
      }, []);
      return newArr;
    }
  }
}
</script>
<style lang="stylus" scoped>
  .userInfoBox
    width 100vw;
    overflow hidden;
    .headerBox
      width 100%;
      min-height 220px;
      background #FFFFFF;
      .firstLineBox
        height 88px;
        width 100%;
        box-sizing border-box;
        padding 0 16px;
        display flex;
        align-items center;
        justify-content space-between;
        .userImage
          width 88px;
          height 88px;
          overflow hidden;
        .operateBox
          height 36px;
          overflow hidden;
          display flex;
          align-items center;
          .commonButton
            width 36px;
            margin-right 12px;
            height 100%;
            border-radius 18px;
            display flex;
            align-items center;
            justify-content center;
            background #E4E5E9;
            &:last-child
              margin-right 0;
            .iconStyle
              font-size 24px;
              color #200E32;
          .chatButton
            padding 0 37px;
            background #33CC66;
      .commonLine
        width 100%;
        height 20px;
        box-sizing border-box;
        padding 0 16px;
        display flex;
        align-items center;
        justify-content flex-start;
        .iconBox
          width 20px;
          height 20px;
          flex-shrink 0;
          margin-right 10px;
          border-radius 50%;
          overflow hidden;
          background #E4E5E9;
          display flex;
          align-items center;
          justify-content center;
          font-size 16px;
          color #200E32
        .genderBox
          width 18px;
          height 18px;
          flex-shrink 0;
          margin-left 10px;
        .fontStyle
          font-weight bold;
          font-size 12px;
        .textStyle
          max-width 100%;
          height 100%;
          line-height 20px;
          min-width 0;
          overflow hidden;
          text-overflow ellipsis;
          white-space nowrap;
      .secondLineBox
        margin-top 30px;
        .textStyle
          color #200E32;
          font-size 18px;
          font-weight bold;
      .thirdLineBox
        margin-top 11px;
        .textStyle
          color #666666;
          font-size 14px;
      .skillsBox
        padding 27px 16px 17px 16px;
        .skillItem
          height 26px;
          min-width 64px;
          margin 0 6px 10px 0;
          border-radius 13px;
          background #F2FCF8;
          box-sizing border-box;
          display inline-flex;
          align-items center;
          justify-content flex-start;
          .skillIcon
            margin-left 3px;
            flex-shrink 0;
            .iconStyle
              font-size 20px;
              color #33CC66
          .skillContent
            flex 1;
            text-align center;
            font-size 12px;
            color #1D4329;
            box-sizing border-box;
            padding 0 10px;
    .tabbarBox
      width 100%;
      height 50px;
      background #FFFFFF;
      border-top 1px solid #F2F2F2;
      display flex;
      align-items center;
      justify-content space-between;
      position relative;
      z-index 7;
      .tabbarItem
        height 100%;
        flex 1;
        display flex;
        align-items center;
        justify-content center;
        border-bottom 1px solid transparent;
        box-sizing border-box;
        .iconStyle
          font-size 30px;
          font-weight bold;


</style>