var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personalGroupList" },
    [
      _vm.groupInfoList.length !== 0
        ? _c(
            "van-list",
            {
              attrs: {
                finished: _vm.groupFinished,
                "finished-text": "---end---"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.groupLoading,
                callback: function($$v) {
                  _vm.groupLoading = $$v
                },
                expression: "groupLoading"
              }
            },
            _vm._l(_vm.groupInfoList, function(item) {
              return _c("van-cell", { key: item.groupId }, [
                _c(
                  "div",
                  {
                    staticClass: "groupItem",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.jumpGroupPage(item.groupId)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "groupHeader" },
                      [
                        _c("van-image", {
                          attrs: {
                            width: "100%",
                            height: "100%",
                            fit: "cover",
                            src: _vm.formartImageUrl(item.groupHeader)
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "error",
                                fn: function() {
                                  return [_vm._v("Melinked")]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "groupType",
                            style: _vm.groupTypeStyle(item.accessPattern)
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont_Me",
                              class: _vm.groupIcon(item.accessPattern)
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "groupInfo" }, [
                      _c("div", { staticClass: "groupName" }, [
                        _c("div", { staticClass: "textBox" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.groupName) +
                              "\n            "
                          )
                        ]),
                        _c("div", { staticClass: "iconBox" }, [
                          _c("i", {
                            staticClass: "iconfont_Me icon_qunzhu iconStyle"
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "groupDescribe" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.groupInfo(item.groupInfo)) +
                            "\n          "
                        )
                      ]),
                      _c("div", { staticClass: "groupStatistics" }, [
                        _c("div", { staticClass: "iconBox" }, [
                          _c("i", {
                            staticClass: "iconfont_Me iconStyle icon_group-fill"
                          })
                        ]),
                        _c("div", { staticClass: "statisticsText" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.memberCount) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            }),
            1
          )
        : _c("PlaceholderMap")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }