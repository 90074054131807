import { render, staticRenderFns } from "./personalPostList.vue?vue&type=template&id=16aa8eec&scoped=true&"
import script from "./personalPostList.vue?vue&type=script&lang=js&"
export * from "./personalPostList.vue?vue&type=script&lang=js&"
import style0 from "./personalPostList.vue?vue&type=style&index=0&id=16aa8eec&lang=stylus&scoped=true&"
import style1 from "./personalPostList.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16aa8eec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16aa8eec')) {
      api.createRecord('16aa8eec', component.options)
    } else {
      api.reload('16aa8eec', component.options)
    }
    module.hot.accept("./personalPostList.vue?vue&type=template&id=16aa8eec&scoped=true&", function () {
      api.rerender('16aa8eec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/business/newVersion/userinfo/personalPostList.vue"
export default component.exports